@import 'common/styles/vars';

.perf-layout {
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .perf-header {
    margin: 0;
    height: 4rem;

    padding-right: 0.7rem;
    padding-left: 0.7rem;

    background-color: $bg-dark;
    border-bottom: solid $bg-light 1px;

    .branding {
      text-decoration: none;
      display: flex;

      padding-top: 0 !important;
      padding-bottom: 0 !important;

      margin-top: auto !important;
      margin-bottom: auto !important;

      font-weight: 600 !important;
    }

    .nav-control {
      font-size: 1.5rem;

      i {
        cursor: pointer;
      }

      &.right {
        display: flex;
        flex-basis: auto;

        align-items: center;
        justify-content: flex-end;

        .header-icon {
          &:hover {
            color: $perf-orange;

            .perf-tooltip {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }

          .perf-tooltip {
            top: 0.2rem;
            right: 2rem;

            font-size: 1rem;

            .tooltip-text {
              background: $bg-light;
            }
            .tooltip-arrow {
              border-left: 8px solid $bg-light;
            }
          }
        }
      }
    }
  }

  .perf-body {
    flex-wrap: nowrap;
    margin: 0;
    flex-grow: 1;

    .perf-nav {
      z-index: 10;
      overflow: hidden;
      white-space: nowrap;

      display: flex;
      flex-direction: column;

      padding: 0 !important;

      width: 15rem;
      height: calc(100vh - 4rem);

      transition: width 0.3s;
      background-color: $bg-dark;

      color: $white;

      &-spacer {
        width: 4.5rem;
      }

      &.xs {
        position: absolute;

        &.collapsed {
          width: 0;
        }
      }

      &.sm {
        position: absolute;
      }

      &.collapsed {
        width: 4.5rem;

        .perf-nav-links {
          .perf-nav-item {
            &:hover {
              .perf-tooltip {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }
          }
        }

        .perf-nav-footer {
          transition: padding-right 0.3s;
          padding-right: 0;

          .perf-nav-item {
            transition: margin 0.5s;
            margin-right: 30%;
          }
        }
      }

      .perf-nav-links {
        flex-grow: 1;

        display: flex;
        flex-direction: column;

        &.bottom {
          flex-grow: 0;
        }

        .perf-nav-item {
          padding-left: 1.35rem !important;
          padding-right: 1.35rem !important;

          flex-direction: row;
          align-items: center;

          &:hover {
            background-color: $bg-md;
          }

          &.active {
            background-color: $bg-light;
            color: $perf-orange;
          }
        }

        .perf-tooltip {
          left: 4.5rem;

          .tooltip-arrow {
            border-right: 8px solid $bg-dark;
          }
        }
      }

      .perf-nav-footer {
        display: flex;
        flex-direction: row;

        justify-content: flex-end;

        transition: padding-right 0.3s;

        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        padding-right: 0.75rem;

        border-top: solid $bg-light 1px;

        .perf-nav-item {
          transition: margin 0.4s;

          .toggle-nav {
            display: flex;

            transition: transform 0.5s;

            &.collapsed {
              transition: transform 0.5s;
              transform: scaleX(-1);
            }
          }
        }
      }
    }

    .perf-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}

.nav-bg-overlay {
  z-index: 2;
  background: $bg-md-transparent;

  &.dark {
    background-color: $bg-body;
  }
}

.perf-nav-item {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: $perf-orange;
  }
}

.bi-sm {
  font-size: 1.3rem;
  padding: 0.2rem 1rem 0 0;
}
.custom-nav-link {
  display: flex;
  align-items: center;
}

.perf-tooltip {
  display: none;
  position: absolute;

  .tooltip-text {
    padding: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;

    color: white;
    border-radius: 0.35rem;
    background: $bg-dark;
  }

  .tooltip-arrow {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
}
