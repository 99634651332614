@import '~/src/common/styles/vars';

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  #error-desc {
    text-align: center;
    color: $bg-dark;

    font-weight: bold;

    h1 {
      font-weight: bold;
    }
  }

  #error-icon {
    color: $perf-orange;
  }
}
