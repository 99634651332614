.help-wrapper {
  margin: 0 -0.5rem;
  width: -webkit-fill-available;
  height: calc(100svh - 3rem);
  height: calc(100vh - 3rem);
}

.help-content {
  max-width: 50rem;
}

// Small tablets and lower
@media only screen and (max-width: 600px) {
  .help-wrapper {
    padding: 0;
    margin: 0;

    ::-webkit-scrollbar {
      display: none !important;
    }
  }
}
