@import '~/src/common/styles/vars';

.perf-section-header {
  #header {
    font-size: 1.2rem;
    font-weight: bold;
    color: $text-dark;
  }

  #sub-header {
    color: $text-gray;
    font-size: 0.9rem;
  }

  #actions {
    svg {
      cursor: pointer;
      color: $text-dark;

      margin: 0 0.5rem 0.25rem 0.5rem !important;

      &#edit,
      &#save {
        &-disabled {
          cursor: auto !important;
          color: $disabled !important;
        }

        &:hover {
          color: $perf-orange;
        }
      }

      &#add {
        &:hover {
          color: $green;
        }
      }

      &#cancel {
        &:hover {
          color: $danger;
        }
      }
    }
  }
}
