@import '~/src/common/styles/vars';
.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
.perf-spinner {
  width: 7rem;
  height: 7rem;
  border-width: 0.4rem;
  color: $perf-orange !important;
  left: calc(50% + 3.5rem);
  z-index: 5;
}
