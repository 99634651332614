@import '~/src/common/styles/vars';

.container {
  display: flex;
  margin: 0.5rem 0 0.8rem 0.5rem;
  width: fit-content;
  align-items: center;
  padding: 0.3rem 0.5rem 0.3rem 0;
  cursor: pointer;
  color: $text-dark !important;
  text-decoration: none !important;
}

.container:hover {
  background-color: $hover-gray;
  transition: 0.2s;
}
