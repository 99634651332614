$text-dark: #323232;
$text-gray: gray;
$text-lightgray: #bfbfbf;
$text-white: white;

$perf-orange: #ec822e;
$perf-orange-blur: #ec822e99;
$perf-purple: #7474c4;
$disabled: #cccccc;
$danger: #dc354b;
$green: #53b553;
$white: white;

$bg-dark: #2c323a;
$bg-md: #383e46;
$bg-light: #444951;
$bg-gray: #e2e3e4;
$bg-lightgray: #f0f0f0;
$bg-body: #f0f2f5;
$bg-white: white;
$bg-md-transparent: #2c323a4a;
$bg-light-orange: #fdd07e;
$bg-overlay: #0000004f;

$hover-white: #f6f6f6;
$hover-gray: #dadada;
$hover-purple: #8989da;
$hover-light-orange: #f4c266;
$hover-orange: #f4a05b;
$hover-lightgray: #d8d8d8;
$hover-red: #bf3644;

$box-shadow-light: lightgray;

$border-md: #b8b8b8;
$border-dark: gray;
$border-light: #ece8e8;
