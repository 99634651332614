@import '~/src/common/styles/vars';

.email-input {
  display: flex;
}
.add-email-btn {
  width: 7rem;
  margin-left: 0.5rem;
  background-color: $perf-orange;
  color: white;
  &:hover {
    background-color: $hover-orange;
    border: none;
    color: white;
  }
}
.save-emails-btn {
  width: 7rem;
}
.email-pill {
  background-color: $bg-lightgray;
  &:hover {
    background-color: $hover-lightgray;
  }
}
.delete-email {
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.width-20 {
  width: 1rem;
}
