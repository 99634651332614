@import '~/src/common/styles/vars';

.load-page-container {
  margin: 0 auto;
}
.load-page-wrapper {
  height: calc(100vh - 4rem);
  overflow-y: auto;
  margin-right: -0.5rem;
  padding-right: 0.5rem;
}
.load-info-item {
  height: 4rem;
}
.load-info-key {
  font-weight: bold;
  min-width: fit-content;
}
.load-info-value {
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
}

// STATUS SECTION STYLES

.statuses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: none !important;
}
.status-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2.8rem;
  .load-delivered {
    display: flex;
    align-items: center;
  }
}
.status-header {
  font-weight: bold;
}
.status-actions-container {
  display: flex;
  align-items: center;
}
.react-datepicker-wrapper {
  width: fit-content;
  font-size: 0.9rem;
}
.react-datepicker-wrapper input {
  padding: 3px 4px 3px 9px;
  border: 1px solid $border-md;
  border-radius: 0.3rem;
  max-width: 8rem;
}

.time-ago {
  margin-top: 0.05rem;
  font-size: 0.7rem;
  color: $text-lightgray;
}
.today-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-gray;
  cursor: pointer;
}
.today-button:hover {
  background-color: $hover-gray;
  transition: 0.2s;
}
.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: $hover-red !important;
    transition: 0.2s;
  }
}

// Comments section
.no-comments {
  background-color: $bg-body;
}
.user-time {
  display: flex;
  align-items: center;
}
.comment-name {
  display: flex;
  align-items: center;
  height: 1rem;
  font-weight: bold;
  font-size: 0.8rem;
}
.comment-me {
  color: $perf-orange;
}
.comment-them {
  color: $perf-purple;
}
.comment-time {
  display: flex;
  align-items: center;
  height: 20px;
  color: gray;
  font-size: 12px;
}
.comment-text {
  font-size: 0.8rem;
  margin-top: 0.7rem;
}
.comment-btn {
  background-color: $perf-purple !important;
  color: $text-white !important;
  outline: none !important;
  border: none !important;
  width: 10rem;

  &:hover {
    background-color: $hover-purple !important;
    transition: 0.2s;
  }
}

// RESPONSIVE

// Small tablets and lower
@media only screen and (max-width: 600px) {
  .load-page-wrapper {
    padding: 0;
    margin-right: 0;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .comment-input {
    width: calc(100% - 1rem);
  }
  .comment-btn {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 500px) {
  .load-info-item {
    width: 100%;
  }
}
