@import '~/src/common/styles/vars';

.perf-filters-bar {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.perf-filters-container {
  display: flex;

  a {
    text-decoration: none;
    color: $text-dark;
  }
}

.perf-dropdown-filter {
  color: $text-dark;
  outline: 0 !important;
  font-size: 14px !important;
  flex-direction: row;
  background-color: $white;
  &:hover {
    background-color: $hover-white !important;
    transition: 0.2s;
    color: $text-dark;
  }
  &:focus {
    color: $text-dark;
  }
}

.perf-filtered {
  color: $text-dark !important;
}

.perf-dropdown-item {
  color: $text-dark !important;
  display: flex !important;
  font-size: 14px !important;
  padding: 6px 10px;
  white-space: nowrap;
  &:hover {
    background-color: $hover-white;
  }
}

.perf-dropdown-filter:active,
.perf-dropdown-toggle:active,
.perf-dropdown-filter:focus,
.perf-dropdown-toggle:focus {
  background-color: $hover-white !important;
  outline: 0 !important;
}

.clear-filters {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 40px;
  margin: 0 3px 1px -5px;
  padding-left: 3px;
  padding-top: 1px;
  &:hover {
    background-color: $hover-white;
    transition: 0.2s;
  }
}

.clear-filter-x {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin-top: -22px;
  margin-left: -12px;
}
.filter-dropdown-no-values {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.disabled {
  user-select: none;
  color: $disabled !important;
  &:hover {
    background-color: $white !important;
  }
}

// Search
.filter-input {
  width: 8.5rem;
  padding-bottom: 0.2rem;
}

// Tablets
@media only screen and (max-width: 1200px) {
  .perf-dropdown-filter {
    font-size: 0.8rem;
    padding: 0.3rem;
  }
}

// Small tablets and lower
@media only screen and (max-width: 600px) {
  .perf-dropdown-filter {
    font-size: 0.7rem;
    padding: 0.3rem;
  }
}
